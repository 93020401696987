<template>
  <b-card class="pt-75">
    <div class="d-flex justify-content-between align-items-center">
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="employeeObject.photo"
            :text="null"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ $route.params.employee_id ? "Actualizar" : "Agregar" }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button variant="primary" @click="$refs.fileInput.click()">
            <input
              ref="fileInput"
              type="file"
              @input="pickFile"
              class="d-none"
            />
            <span class="d-none d-sm-inline">Actualizar</span>
            <i class="far fa-edit d-inline d-sm-none"></i>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click="employeeObject.photo = ''"
          >
            <span class="d-none d-sm-inline">Eliminar</span>
            <i class="fas fa-trash d-inline d-sm-none"></i>
          </b-button>
        </div>
      </b-media>
    </div>
    <validation-observer ref="form">
      <b-form>
        <h4>Información personal</h4>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Nombre" label-for="name">
              <b-form-input
                id="name"
                v-model="employeeObject.first_name"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Segundo nombre" label-for="middle_name">
              <b-form-input
                id="middle_name"
                v-model="employeeObject.middle_name"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Apellidos" label-for="last_name">
              <b-form-input
                id="last_name"
                v-model="employeeObject.last_name"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Conocido como" label-for="known_as">
              <b-form-input
                id="known_as"
                v-model="employeeObject.known_as"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label-for="type_doc">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <span>Tipo de documento</span>
                </div>
              </template>
              <v-select
                id="type_doc"
                :clearable="false"
                v-model="employeeObject.doc_type"
                label="name"
                :options="arrayDocumentTypes"
                :reduce="(option) => option.item"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Número de doc" label-for="doc_num">
              <b-form-input
                id="doc_num"
                v-model="employeeObject.document_number"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Nacionalidad" label-for="nationality">
              <b-form-input
                id="nationality"
                v-model="employeeObject.nationality"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Fecha de nacimiento" label-for="dob">
              <b-form-datepicker
                id="dob"
                locale="es"
                size="sm"
                v-model="employeeObject.dob"
                placeholder="Elige una fecha"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label-for="gender">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <span>Género</span>
                </div>
              </template>
              <v-select
                id="gender"
                :clearable="false"
                label="name"
                v-model="employeeObject.gender"
                :options="arrayGenders"
                :reduce="(option) => option.item"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label-for="martial_status">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <span>Estado marital</span>
                </div>
              </template>
              <v-select
                id="martial_status"
                :clearable="false"
                label="name"
                v-model="employeeObject.martial_status"
                :options="arrayMaritalStatuses"
                :reduce="(option) => option.item"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h4>Información de conctacto</h4>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Dirección" label-for="mailling_address">
              <b-form-input
                id="mailling_address"
                v-model="employeeObject.mailling_address"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Ciudad" label-for="city">
              <b-form-input id="city" v-model="employeeObject.city" size="sm" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Distrito" label-for="state">
              <b-form-input
                id="state"
                v-model="employeeObject.state"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="País" label-for="country">
              <b-form-input
                id="country"
                v-model="employeeObject.country"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Teléfono de casa" label-for="home_phone">
              <b-form-input
                id="home_phone"
                v-model="employeeObject.home_phone"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Celular" label-for="mobile_phone">
              <b-form-input
                id="mobile_phone"
                v-model="employeeObject.mobile_phone"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Teléfono de trabajo" label-for="work_phone">
              <b-form-input
                id="work_phone"
                v-model="employeeObject.work_phone"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Correo de trabajo" label-for="work_email">
              <b-form-input
                id="work_email"
                v-model="employeeObject.work_email"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Correo privado" label-for="private_email">
              <b-form-input
                id="private_email"
                v-model="employeeObject.private_email"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h4>Información de emergencia</h4>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Nombre de contacto" label-for="contact_name">
              <b-form-input
                id="contact_name"
                v-model="employeeObject.contact_name"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group
              label="Teléfono de contacto"
              label-for="contact_phone"
            >
              <b-form-input
                id="contact_phone"
                v-model="employeeObject.contact_phone"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label-for="relation">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <span>Relación</span>
                </div>
              </template>
              <v-select
                id="relation"
                :clearable="false"
                label="name"
                v-model="employeeObject.relation"
                :options="arrayRelations"
                :reduce="(option) => option.item"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"></b-col>
          <b-col cols="12" md="3">
            <b-form-group
              label="Nombre de contacto"
              label-for="contact_name_second"
            >
              <b-form-input
                id="contact_name_second"
                v-model="employeeObject.contact_name_second"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group
              label="Teléfono de contacto"
              label-for="contact_phone_second"
            >
              <b-form-input
                id="contact_phone_second"
                v-model="employeeObject.contact_phone_second"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label-for="relation_second">
              <template #label>
                <div class="d-flex justify-content-between align-items-center">
                  <span>Relación</span>
                </div>
              </template>
              <v-select
                id="relation_second"
                :clearable="false"
                label="name"
                v-model="employeeObject.relation_second"
                :options="arrayRelations"
                :reduce="(option) => option.item"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"></b-col>
        </b-row>
        <h4>Detalles del trabajo</h4>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Fecha de inicio" label-for="start_date">
              <b-form-datepicker
                id="start_date"
                locale="es"
                v-model="employeeObject.start_date"
                size="sm"
                placeholder="Elige una fecha"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Fecha de termino" label-for="end_date">
              <b-form-datepicker
                id="end_date"
                locale="es"
                v-model="employeeObject.end_date"
                size="sm"
                placeholder="Elige una fecha"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="3"></b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Cargo" label-for="position">
              <b-form-input
                id="position"
                v-model="employeeObject.position"
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Sueldo" label-for="salary">
              <money
                id="salary"
                v-model="employeeObject.salary"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: 'S/ ',
                  precision: 2,
                  masked: false,
                }"
                class="form-control form-control-sm"
              ></money>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- <pre>{{employeeObject}}</pre> -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="saveEmployee"
        v-if="!show"
      >
        Guardar
      </b-button>
      <b-button
        variant="primary"
        disabled
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        v-else
      >
        <b-spinner small />
        Loading...
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        @click="resetEmployee"
        v-if="!$route.params.employee_id"
      >
        Resetear
      </b-button>
      <b-button
        variant="outline-secondary"
        role="button"
        to="/main/administrativo/employees"
        v-if="$route.params.employee_id"
      >
        Cancelar
      </b-button>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { Money } from "v-money";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    mounted() {
      if (this.$route.params.employee_id) {
        this.getEmployee();
      }
    },
  data() {
    return {
      show: false,
      arrayDocumentTypes: [
        { item: 1, name: "DNI" },
        { item: 2, name: "Pasaporte" },
        { item: 3, name: "PTP" },
        { item: 4, name: "Otro" },
      ],
      arrayGenders: [
        { item: 1, name: "Masculino" },
        { item: 2, name: "Femenino" },
      ],
      arrayMaritalStatuses: [
        { item: 1, name: "Soltero(a)" },
        { item: 2, name: "Casado(a)" },
        { item: 3, name: "Separado(a)" },
        { item: 4, name: "Divorciado(a)" },
        { item: 5, name: "Viudo(a)" },
      ],
      arrayRelations: [
        { item: 1, name: "Esposo(a)" },
        { item: 2, name: "Socio(a)" },
        { item: 3, name: "Madre" },
        { item: 4, name: "Padre" },
        { item: 5, name: "Hermana" },
        { item: 6, name: "Hermano" },
        { item: 7, name: "Hijo" },
        { item: 8, name: "Hija" },
        { item: 9, name: "Amigo" },
        { item: 10, name: "Otro" },
      ],
      employeeObject: {
        employee_id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        known_as: null,
        doc_type: null,
        document_number: null,
        doe: null,
        nationality: null,
        dob: null,
        gender: null,
        martial_status: null,
        photo: null,
        mailling_address: null,
        city: null,
        state: null,
        zip_code: null,
        country: null,
        home_phone: null,
        mobile_phone: null,
        work_phone: null,
        work_email: null,
        private_email: null,
        contact_name: null,
        contact_phone: null,
        relation: null,
        contact_name_second: null,
        contact_phone_second: null,
        relation_second: null,
        start_date: null,
        end_date: null,
        position: null,
        created_by: null,
        photo_name: null,
        salary: 0,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      }
    };
  },
  components: {
    vSelect,
    Money,
  },
  methods: {
    getEmployee() {
      axios
        .post(`/api/employee/get-employee`, {
            employee_id: this.$route.params.employee_id
        })
        .then((response) => {
            this.employeeObject = response.data[0];
        });
    },
    saveEmployee() {
      this.$refs.form.validate().then((confirm) => {
        if (!confirm) {
          return;
        }
        this.show = true;
        this.employeeObject.created_by = this.currentUser.user_id;
        axios
          .post("/api/employee/save-employeee", this.employeeObject)
          .then((response) => {
              this.showToast(
                  "success",
                  "top-right",
                  "Felicidades",
                  "CheckIcon",
                  this.$route.params.employee_id?'Empleado actualizado!':'Empleado registrado!'
                );
            this.$router.push('/administrativo/empleados');
            this.show = false;
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.employeeObject.photo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.employeeObject.photo_name = file[0].name;
      }
    },
    showToast(variant, position, title, icon, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },
    resetEmployee() {
      (this.employeeObject.employee_id = null),
        (this.employeeObject.first_name = null),
        (this.employeeObject.middle_name = null),
        (this.employeeObject.last_name = null),
        (this.employeeObject.known_as = null),
        (this.employeeObject.doc_type = null),
        (this.employeeObject.document_number = null),
        (this.employeeObject.doe = null),
        (this.employeeObject.nationality = null),
        (this.employeeObject.dob = null),
        (this.employeeObject.gender = null),
        (this.employeeObject.martial_status = null),
        (this.employeeObject.photo = null),
        (this.employeeObject.mailling_address = null),
        (this.employeeObject.city = null),
        (this.employeeObject.state = null),
        (this.employeeObject.zip_code = null),
        (this.employeeObject.country = null),
        (this.employeeObject.home_phone = null),
        (this.employeeObject.mobile_phone = null),
        (this.employeeObject.work_phone = null),
        (this.employeeObject.work_email = null),
        (this.employeeObject.private_email = null),
        (this.employeeObject.contact_name = null),
        (this.employeeObject.contact_phone = null),
        (this.employeeObject.relation = null),
        (this.employeeObject.contact_name_second = null),
        (this.employeeObject.contact_phone_second = null),
        (this.employeeObject.relation_second = null),
        (this.employeeObject.start_date = null),
        (this.employeeObject.end_date = null),
        (this.employeeObject.position = null),
        (this.employeeObject.created_by = null),
        this.$refs.form.reset();
    },
  },
};
</script>

